import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PageComp from '../components/PageComp'
import LinkCloud from '../components/LinkCloud/LinkCloud'

const Contact = ({ data }) => {
  const { title: siteTitle, siteUrl } = data.site.siteMetadata
  const contact = true
  return (
    <Layout>
      <Helmet>
        <title>{`Contact | ${siteTitle}`}</title>
        <link rel="canonical" href={`${siteUrl}/contact/`} />
        <meta name="description" content="Contact" />
        <meta name="og:title" content={`Contact | ${siteTitle}`} />
        <meta name="og:description" content="Contact" />
      </Helmet>
      <PageComp title="Contact" content="" contact={contact} />
      <LinkCloud />
    </Layout>
  )
}

export default Contact

export const queryContact = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
